import React, { useState, useEffect, Component,useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/pages.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";
import Loadder from "./Loadder";

const Monthly_salary_list = () => {

   const locusr = JSON.parse(localStorage.getItem("user"));
   let user_typ = Apiconnect.decrypt(locusr.emp_typ);
   const alert = useAlert();

   let history = useHistory();
   const csvLink = useRef();
   const csvLink2 = useRef();
   const csvLink3 = useRef();
   useEffect(()=>{
    getSalarySlipData();
   
   },[])

   const [salaryMonths, setSallaryData] = useState([]);
   const [salaryMonthsExcel, setSalaryMonthsExcel] = useState([]);
   const [dwnSalaryMonthsById, setdwnSalaryMonthsById] = useState([]);
   const [dwnEsiSalaryMonthsById, setdwnEsiSalaryMonthsById] = useState([]);
   const [dwnPfSalaryMonthsById, setdwnPfSalaryMonthsById] = useState([]);
   const [getExcelName, setExcelName] = useState([]);

   const getSalarySlipData = () => {
    Apiconnect.getData(`employee_monthly_salary/getSalarySlipAllMonths/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       var monthsList=_xtract;
       setSallaryData(monthsList);
       loadExcelData(monthsList)
    });
 };

 const loadExcelData = async (monthsList) => {
         var newList = [];
         monthsList.map( async (vvx, kkx) => {
         var yearmon= vvx.year+''+vvx.month;
         var salaryMonth = await get_monthData(yearmon);
         vvx['dwnEmpSalaryList'] = salaryMonth;
          
         var esiSalaryMonth = await getEsiMonthData(yearmon);
         vvx['dwnEmpEsiSalaryList'] = esiSalaryMonth;  
         
         var pfSalaryMonth = await getPfMonthData(yearmon);
         vvx['dwnEmpPfSalaryList'] = pfSalaryMonth;  

         newList.push(vvx);
          if(kkx === monthsList.length-1){
            setSalaryMonthsExcel(newList);
          } 
       });
 }
 const get_monthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getSalaryDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         //  console.log('salData',salData);
         return salData;
     });
     return salaryData;
 }; 

 const getEsiMonthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getEsiDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         return salData;
     });
     return salaryData;
 }; 

 const getPfMonthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getPfDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         console.log('salData',salData)
         return salData;
     });
     return salaryData;
 }; 

 const downloadExcel = (id,name) => {
   setExcelName('SalaryList_'+name);
    let data = salaryMonthsExcel.find(val => val.id === id)
    if(data){
      setdwnSalaryMonthsById(data.dwnEmpSalaryList)
      setTimeout(function () {csvLink.current.link.click();}, 2000);
    }
   
 }
 const downloadESIExcel = (id,name) => {
   setExcelName('Esi_'+name);
   let data = salaryMonthsExcel.find(val => val.id === id)
   if(data){
     setdwnEsiSalaryMonthsById(data.dwnEmpEsiSalaryList)
     setTimeout(function () {csvLink2.current.link.click();}, 2000);
   }
 }

 const downloadPFExcel = (id,name) => {
   setExcelName('PF_'+name);
   let data = salaryMonthsExcel.find(val => val.id === id)
   if(data){
     setdwnPfSalaryMonthsById(data.dwnEmpPfSalaryList)
     setTimeout(function () {csvLink3.current.link.click();}, 2000);
   }
 }

 const unblock = (id) => {

   const inf_a = {id:id,status:0};
   Apiconnect.postData("employee_monthly_salary/lock_month", inf_a).then((response) => {
   alert.success("Unblock Successfully");
   getSalarySlipData();

 });

 }

 const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
 '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP','10' :'OCT','11' :'NOV','12' :'DEC'};

 const viewItem = (e , status,id)=> {
    history.push(`/employee_month_sal/${e}/${id}/${status}`); 
    }

   return (
    <>     
           <div id="content-page" className="content-page">
            <div className="container-fluid">
            <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Monthly Salary</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                              <thead className="">
                                 <tr className="clr" style={{textAlign:'center'}}>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody className="">
                                {
                                    salaryMonths.map((value,key)=>{
                                        return (
                                        <tr key={key} style={{textAlign:'center'}}>
                                        <td style={{width:'150px'}} >{key+1}</td>
                                        <td style={{textAlign:'center'}}>{months[value.month]+'-'+value.year}</td>
                                        <td>{value.status === 0 ? 'Unlock' : 'Lock'}</td>
                                        <td style={{width:'450px'}}>
                                        <button  className="btn btn-sm  btn-info mr-2" onClick={() => viewItem(value.year+''+value.month,value.status,value.id)}><i className="fa fa-eye" aria-hidden="true"></i></button>
                                        <button  className="btn btn-sm  btn-primary mr-2" onClick={() => downloadExcel(value.id,months[value.month]+'-'+value.year)}><i className="fa fa-download"></i>SalarySheet</button>
                                        <button  className="btn btn-sm  btn-info mr-2" onClick={() => downloadESIExcel(value.id,months[value.month]+'-'+value.year)}><i className="fa fa-download"></i>ESI</button>
                                        <button  className="btn btn-sm  btn-warning mr-2" onClick={() => downloadPFExcel(value.id,months[value.month]+'-'+value.year)}><i className="fa fa-download"></i>PF Statement</button>
                                        {
                                           user_typ == '3' && value.status === 1
                                             ? ( <button  className="btn btn-sm  btn-success mr-2" onClick={() => unblock(value.id)}>Unblock</button>)
                                             : null
                                        }
                                          <CSVLink
                                             data={dwnSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                            
                                             className="hidden"
                                             ref={csvLink}
                                             target="_blank"
                                             /> 
                                          
                                          <CSVLink
                                             data={dwnEsiSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink2}
                                             target="_blank"
                                             /> 
                                           <CSVLink
                                             data={dwnPfSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink3}
                                             target="_blank"
                                             /> 
                                        </td>
                                    </tr>
                                        )
                                    })
                                    }
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
            </div>
            </div>
    </>
   )

}
export default Monthly_salary_list