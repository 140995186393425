
import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';
import Loadder from "./Loadder";

// import 'bootstrap-less/bootstrap/bootstrap.less';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const Permission_group_list = () => {

    const alert = useAlert();

   const [loader, setLoader] = useState(false);
   const [gPList, setGPList] = useState([]);
   const [gAllPList, setGellPList] = useState([]);
   const [allchecked, setallchecked] = useState(null);
   const [selectedPermisssion, setSelectedPermission] = useState([]);
   const [groupId, setGroupId] = useState([]);
   
   useEffect(() => {getGP();getAllPermission()}, []);

    const getItem = (id) => {  
        setGroupId(id)
      setModalIsOpen(true);  
      setSelectedPermission([])
      Apiconnect.getData(`permission/getPermission/${id}`).then((response) => {        
         let _xtract = response.data.data;    

         if(_xtract[0]){
            let dd = _xtract[0].gp_permission_id.split(',') ; 
            let ob = {};
            dd.map((val, key) => {
              if(val){
                ob[val] = val;
              }
               })
               setSelectedPermission(ob);
         }

      });
    };

    const getAllPermission = () => {  
        Apiconnect.getData(`permission/getAllPermission`).then((response) => {        
           let _xtract = response.data.data;   
           setGellPList(_xtract)   
        });
         
      };

   const getGP = () => {
    setLoader(true)
      Apiconnect.getData(`permission/getAll`).then((response) => {
         let _xtract = response.data.data;
         console.log('_xtract',_xtract)
         setGPList(_xtract);   
         setLoader(false)
      });
   };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         height:'800px',
         width:'800px',
        
      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   const sellectAll = (e) => {
    //    console.log('gAllPList',gAllPList)
    if (e.target.checked === true) {
      setallchecked("checked");
      let ob = {};
      gAllPList.map((val, key) => {
        ob[val.permission_id] = val.permission_id;
      });
      setSelectedPermission(ob);
    } else {
      setallchecked(null);
      setSelectedPermission([]);
    }
  };

  const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
      setSelectedPermission({
        ...selectedPermisssion,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedPermission({ ...selectedPermisssion, [e.currentTarget.name]: 0 });
    }
  };

  const applyAll = async (e) => {
    let selectedP = Object.values(selectedPermisssion).filter(Boolean).join(',');
    const inf_a = {gp_group_id:groupId,gp_permission_id: selectedP };
    await Apiconnect.postData("permission/create", inf_a).then(
      (response) => {}
    );
    alert.success("Permission Apply Successfully");
    setModalIsOpen(false);
    setSelectedPermission([]);
    setallchecked(null);

  };


   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">
    
          <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Permission List</h4>
                           </div>
                        </div>
                        <button style={{float:'right',margin:'10px'}} onClick={() => {applyAll();}} className="btn btn-info mr-2">Apply</button>
                        <div className="iq-card-body">
                        <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                    <th scope="col">
                                    <input
                                        type="checkbox"
                                        name="select_all"
                                        checked={allchecked}
                                        onChange={(e) => sellectAll(e)}
                                    />
                                    </th>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                    loader ? <Loadder/>:
                                    gAllPList.map((val, key) => { 
                                        
                                        var ck = Object.keys(selectedPermisssion).find(function (
                                            element
                                          ) {
                                            return selectedPermisssion[element] == val.permission_id;
                                          });
                
                                          var ck1 = "";
                                          if (ck !== undefined) {
                                            ck1 = true;
                                          }
                                          
                                       return (
                                          <tr key={key}>  
                                           <th scope="row">
                                            <input
                                            type="checkbox"
                                            name={val.permission_id}
                                            value={val.permission_id}
                                            checked={ck1}
                                            onChange={(e) => onInputChange2(e)}
                                            />
                                        </th>
                                             <th scope="row">{key+1}</th>
                                             <td>{val.permission_title}</td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div> 


               <div className="row">
                   <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of Permission Group</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">

                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       {/* <th scope="col">State</th> */}
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                    loader ? <Loadder/>:
                                    gPList.map((val, key) => {       
                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.employee_permission_group_name}</td>
                                             <td>
                                             <button onClick={() => {getItem(val.employee_permission_group_id);}}
                                             className="btn btn-success mr-2">Set Permission</button>
                                             </td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>    
               </div>
         </div>
         </div>
      </>
   )
}

export default Permission_group_list