import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";

function Header() {

   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let downline = localuser.downline; 
   let username = localuser.username;

   

   function down(){
      if (downline > 0 ){
         return (
                  <li>
                     <a href="#emp_manage" className="iq-waves-effect collapsed"  data-toggle="collapse" aria-expanded="false">
                        <i className="ri-chat-check-line"></i><span>Team Management</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                     <ul id="emp_manage" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                        {
                        //<li><Link to="/ems/team_member_attendance">Team Member Attendance</Link></li>
                         
                        }
                        <li><Link to="/ems/my_team">My Team</Link></li>
                        <li><Link to="/ems/attendance_master_report">Attendance Master Report</Link></li>
                        <li><Link to="/ems/manual_attendance_master">Manual Attendance</Link></li>
                        <li><Link to="/ems/team_member_leaves">Team Member Leaves</Link></li>
                        <li><Link to="/ems/team_member_short_leaves">Team Member Short Leave</Link></li>
                     </ul>
                  </li>
               )
      } else{
         return null;
      } 
    }

   
   
   function logOut() {
      AuthService.logout();
      history.push("/");
   }

   function resetPassword(){
      history.push("/ems/resetPassword/");
   }

   const locusr = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(locusr.id);
   let company_name = locusr.company_name;
   let fullname = locusr.fullname;
  

   const [profile_image, setprofile_image] = useState(null);
   const [logo, setlogo] = useState(null);

   useEffect(() => {getprofile();getCompany();}, []);
   const getprofile = () => {  
 
      Apiconnect.getData(`employee/get/${user_id}`).then((response) => {        
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
        
         if(_xtract[0].display_picture == null){
            setprofile_image('/images/user/1.jpg'); 
         }else{
            var img = process.env.React_App_BASE_URL_API+'/uploads/thumbdp/'+_xtract[0].display_picture;
            setprofile_image(img); 
         }
         // setInfo({ name: _xtract[0].name, state_id: _xtract[0].state_id, id: _xtract[0].id });      
      });
    };

    const getCompany = () => {
      Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         if (_xtract[0].logo == null) {
            setlogo("images/logo.png");
         } else {
            var img = `${process.env.React_App_BASE_URL_API}/uploads/logo/` + _xtract[0].logo;
            setlogo(img);
         }
      });
   }
    
    return (
<>
            <div id="loading">
             <div id="loading-center">
                <div className="loader">
                   <div className="cube">
                      <div className="sides">
                         <div className="top"></div>
                         <div className="right"></div>
                         <div className="bottom"></div>
                         <div className="left"></div>
                         <div className="front"></div>
                         <div className="back"></div>
                      </div>
                   </div>
                </div>
             </div>
            </div>

            <div className="wrapper"> 

        <div className="iq-sidebar">
            <div className="iq-sidebar-logo d-flex justify-content-between">
               <Link to="">
               <img src={logo} className="img-fluid" alt="" />
               {/* <span>HRMS</span> */}
               </Link>
               <div className="iq-menu-bt align-self-center">
                  <div className="wrapper-menu">
                     <div className="line-menu half start"></div>
                     <div className="line-menu"></div>
                     <div className="line-menu half end"></div>
                  </div>
               </div>
            </div>
            <div id="sidebar-scrollbar">
               <nav className="iq-sidebar-menu">
                  <ul id="iq-sidebar-toggle" className="iq-menu">
                     <li className="iq-menu-title"><i className="ri-separator"></i><span>Main</span></li>
                     <li>
                        <Link to="/ems/dashboard"><i className="ri-home-4-line"></i> Dashboard</Link>
                        
                     </li>
                      

                     <li><Link to="/ems/leave"><i className="fa fa-ticket"></i> Leave</Link></li>
                     <li><Link to="/ems/short_leave"><i className="fa fa-ticket"></i> Short Leave</Link></li>
                     
                     <li><Link to="/ems/yearly_holidays"><i className="fa fa-calendar-check-o"></i> Holidays</Link></li>
                     <li><Link to="/ems/daily_attendance"><i className="fa fa-calendar-check-o"></i> Attendance</Link></li>
                     <li><Link to="/ems/manual_attendance"><i className="fa fa-calendar-check-o"></i> Manual Attendance</Link></li>

                     <li><Link to="/ems/reimbursement"><i className="fa fa-gift"></i> Reimbursement</Link></li>
                     <li><Link to="/ems/conveyance"><i className="fa fa-car"></i> Conveyance</Link></li>
                     <li><Link to="/ems/salary_breakup"><i className="fa fa-ticket"></i> Salary Breakup</Link></li>
                     <li><Link to="/ems/salary_slip"><i className="fa fa-ticket"></i> Salary Slip</Link></li>

                     
                     {down()}   


 

 
 
                  </ul>
               </nav>
               <div className="p-3"></div>
            </div>
        </div>





        <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
               <div className="iq-sidebar-logo">
               <div className="top-logo">
                  <Link to=""  className="logo">
                  <img src={logo} className="img-fluid" alt="" />
                  <span style={{paddingLeft:'5px'}}>{company_name}</span>
                  </Link>
               </div>
            </div>
                
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                <h5 className="mb-0 pl-3">{company_name}</h5>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="ri-menu-3-line"></i>
                  </button>
                  <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                     <ul className="navbar-nav ml-auto navbar-list">
                        
                          
                        <li className="nav-item">
                           <a href="#" className="search-toggle iq-waves-effect">
                              <i className="ri-notification-2-line"></i>
                              <span className="bg-danger dots"></span>
                           </a>
                           <div className="iq-sub-dropdown">
                              <div className="iq-card shadow-none m-0">
                                 <div className="iq-card-body p-0 ">
                                    <div className="bg-danger p-3">
                                       <h5 className="mb-0 text-white">All Notifications<small className="badge  badge-light float-right pt-1">4</small></h5>
                                    </div>
                                    <a href="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">New Order Recieved</h6>
                                             <small className="float-right font-size-12">23 hrs ago</small>
                                             <p className="mb-0">Lorem is simply</p>
                                          </div>
                                       </div>
                                    </a>
                                    <a href="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="">
                                             <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Emma Watson Nik</h6>
                                             <small className="float-right font-size-12">Just Now</small>
                                             <p className="mb-0">95 MB</p>
                                          </div>
                                       </div>
                                    </a>
                                    <a href="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="">
                                             <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">New customer is join</h6>
                                             <small className="float-right font-size-12">5 days ago</small>
                                             <p className="mb-0">Jond Nik</p>
                                          </div>
                                       </div>
                                    </a>
                                    <a href="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="">
                                             <img className="avatar-40" src="images/small/jpg.svg" alt="" />
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Updates Available</h6>
                                             <small className="float-right font-size-12">Just Now</small>
                                             <p className="mb-0">120 MB</p>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </li>
                        <li className="nav-item iq-full-screen"><a href="#" className="iq-waves-effect" id="btnFullscreen"><i className="ri-fullscreen-line"></i></a></li>
                     </ul>
                  </div>
                  <ul className="navbar-list">
                     <li>
                        <a href="#" className="search-toggle iq-waves-effect bg-primary text-white"><img src={profile_image} className="img-fluid rounded" alt="user" /></a>
                        <div className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card shadow-none m-0">
                              <div className="iq-card-body p-0 ">
                                 <div className="bg-primary p-3">
                                    <h5 className="mb-0 text-white line-height">{username}</h5>
                                    <span className="text-white font-size-12">Available</span>
                                 </div>

                                 <Link to="/ems/editProfile" className="iq-sub-card iq-bg-primary-hover">
                                    <div className="media align-items-center">
                                       <div className="rounded iq-card-icon iq-bg-primary">
                                          <i className="ri-file-user-line"></i>
                                       </div>
                                       <div className="media-body ml-3">
                                          <h6 className="mb-0 ">My Profile</h6>
                                          <p className="mb-0 font-size-12">View your profile details.</p>
                                       </div>
                                    </div>
                                 </Link>

                                 <Link to={`/ems/employee_upload_image`} className="iq-sub-card iq-bg-primary-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-primary">
                                             <i className="ri-file-user-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Change Profile Pic</h6>
                                          </div>
                                       </div>
                                    </Link>
                                  
                                 
                                 
                                 <div className="d-inline-block w-100  p-2">
                                    <a className="iq-bg-danger iq-sign-btn" onClick={ logOut } role="button" >Sign out<i className="ri-login-box-line ml-2"></i></a>
                                    <a className="iq-bg-info " onClick={ resetPassword } role="button" style={{padding: '8.5px',borderRadius: '5px',border: '1px solid transparent',cursor:'pointer'}}>Reset Password<i className="ri-login-box-line ml-2"></i></a>   
                                 </div>
                                 {/* <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn" onClick={ logOut } role="button">Sign out<i className="ri-login-box-line ml-2"></i></a>
                                 </div> */}
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav>
            </div>
        </div>




















            </div>
       
          
</>
    )
}

export default Header
