import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./ems_pages.css";
import "./userstyle.css";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helper from "../../services/HelperCodebase.js";
import { logDOM } from "@testing-library/react";

function LeaveApply() {
  const alert = useAlert();
  let history = useHistory();

  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp = Apiconnect.decrypt(localuser.id);

  const [info, setInfo] = useState({
    leave_typ: "",
    reason: "",
    company_id: Apiconnect.get_company_id(),
    id: null,
    emp_id: emp,
    typ: "debit",
    status: 0,
  });
  const { leave_typ, reason, id } = info;

  const [infoz, setInfoz] = useState({
    // creadited_leaves:0,
    // debited_leaves:0,
    // carryforward_leaves:0,
    fromDate: new Date(),
    toDate: new Date(),
    mxDate: new Date(),
  });
  const { fromDate, toDate, mxDate } = infoz;

  const [creadited_leaves, setcreadited_leaves] = useState(0);
  const [debited_leaves, setdebited_leaves] = useState(0);
  const [carryforward_leaves, setcarryforward_leaves] = useState(0);
  const [leavestypelist, setleavestypelist] = useState([]);

  const onInputChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });

    if (e.target.name == "leave_typ") {
      //console.log(e.target.name);
      //console.log(e.target.value);
      var typid = e.target.value;
      var this_typ = Helper.array_search_multidim("id", typid, List);

      var min_days = this_typ[0].leave_apply_days_min;
      var max_days = this_typ[0].leave_apply_days_max;

      var tx = parseInt(min_days) + parseInt(max_days);

      var myminDate = Helper.add_days(new Date(), min_days);
      var mymaxDate = Helper.add_days(new Date(), tx);
      setInfoz({
        ...infoz,
        fromDate: new Date(myminDate),
        toDate: new Date(myminDate),
        mxDate: new Date(mymaxDate),
      });
    }
  };
  const fromDateChage = async (e) => {
    var mymaxDate = Helper.add_days(e, 10);
    setInfoz({
      ...infoz,
      fromDate: e,
      toDate: e,
      mxDate: new Date(mymaxDate),
    });
  };
  const toDateChage = async (e) => {
    setInfoz({ ...infoz, toDate: e });
  };

  const [List, setleaveType] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [reportor, setReportorList] = useState({
    first_name: "",
    last_name: "",
  });
  const { first_name, last_name } = reportor;

  useEffect(() => {
    leaveType();
    getLeaveList();
    getReportor();
    // getCreditedLeave();
    // getdebitedLeave();
    // getCarryforwardLeave();
    getLeaveByType();
  }, []);

  const leaveType = () => {
    Apiconnect.getData(
      `company_leave_policy/getall/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveType(_xtract.reverse());
    });
  };
  const getLeaveList = () => {
    Apiconnect.getData(
      `employee_leave/getall/${Apiconnect.get_company_id()}/${emp}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveList(_xtract.reverse());
    });
  };
  const getReportor = () => {
    Apiconnect.getData(`employee_leave/leaveapprovalauthority/${emp}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        console.log(_xtract);
        if (_xtract.lenght > 0) {
          setReportorList({
            first_name: _xtract[0].first_name,
            last_name: _xtract[0].last_name,
          });
        }
      }
    );
  };

  //////////////----------------Leave summary------------------///////////////////////

  const getLeaveByType = () => {
    Apiconnect.getData(`employee_leave/getAllLeaveByType/${emp}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setleavestypelist(_xtract.reverse());
      }
    );

    Apiconnect.getData(`employee_leave/totalcreditedleave/${emp}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        if (_xtract[0].creditedleaves !== null) {
          setcreadited_leaves(_xtract[0].creditedleaves);
        }
      }
    );

    Apiconnect.getData(`employee_leave/totaltakenleave/${emp}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        if (_xtract[0].debitleaves !== null) {
          setdebited_leaves(_xtract[0].debitleaves);
        }
      }
    );

    Apiconnect.getData(`employee_leave/totalcarryforwardleave/${emp}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        if (_xtract[0].carryforwardleaves !== null) {
          setcarryforward_leaves(_xtract[0].carryforwardleaves);
        }
      }
    );
  };

  //////////////----------------End Leave summary------------------///////////////////////

  // const getCreditedLeave = () => {
  //   Apiconnect.getData(`employee_leave/totalcreditedleave/${emp}`).then((response) => {
  //     let _xtract = Apiconnect.decrypt_obj(response.data.data);
  //     if (_xtract[0].creditedleaves !== null) {
  //       setcreadited_leaves(_xtract[0].creditedleaves);
  //     }
  //   });
  // }
  // const getdebitedLeave = () => {
  //   Apiconnect.getData(`employee_leave/totaltakenleave/${emp}`).then((response) => {
  //     let _xtract = Apiconnect.decrypt_obj(response.data.data);
  //     if (_xtract[0].debitleaves !== null) {
  //       setdebited_leaves(_xtract[0].debitleaves);
  //     }
  //   });
  // }
  // const getCarryforwardLeave = () => {
  //   Apiconnect.getData(`employee_leave/totalcarryforwardleave/${emp}`).then((response) => {
  //     let _xtract = Apiconnect.decrypt_obj(response.data.data);
  //     if (_xtract[0].carryforwardleaves !== null) {
  //       setcarryforward_leaves(_xtract[0].carryforwardleaves);
  //     }
  //   });
  // }

  ///////////////////////////////////////////////////////////////////////////////////

  const onSubmit = async (e) => {
    e.preventDefault();

    await Apiconnect.getData(
      `employee_leave/getLeaveByTypeId/${emp}/${info.leave_typ}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      let _xtract1 = Apiconnect.decrypt_obj(response.data.data1);
      let _xtract2 = Apiconnect.decrypt_obj(response.data.data2);

      if (_xtract[0].credit_cnt !== null) {
        var creadited_leaves1 = _xtract[0].credit_cnt;
      } else {
        var creadited_leaves1 = 0;
      }
      if (_xtract1[0].debit_cnt !== null) {
        var debited_leaves1 = _xtract1[0].debit_cnt;
      } else {
        var debited_leaves1 = 0;
      }
      if (_xtract2[0].carryforward_cnt !== null) {
        var carryforward_leaves1 = _xtract2[0].carryforward_cnt;
      } else {
        var carryforward_leaves1 = 0;
      }

      console.log("in add function");
      info.leave_start_date = format(fromDate, "yyyy-MM-dd");
      info.leave_end_date = format(toDate, "yyyy-MM-dd");

      var Difference_In_Time = toDate.getTime() - fromDate.getTime();
      var Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );
      var Final_Result = Difference_In_Days.toFixed(0);
      info.leave_count = Number(Final_Result) + 1;

      const available_leave =
        carryforward_leaves1 + creadited_leaves1 - debited_leaves1;
      const lop = Number(available_leave) - (Number(Final_Result) + 1);

      if (lop < 0) {
        info.lop = Math.abs(lop);
      } else {
        info.lop = 0;
      }

      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc, user_id: emp };
      Apiconnect.postData("employee_leave/create_by_empid", inf_a).then(
        (response) => {
          // console.log(response);
          history.push("/ems/leave");
          setInfo({
            leave_typ: "",
            reason: "",
            id: "",
            company_id: Apiconnect.get_company_id(),
            emp_id: emp,
            typ: "debit",
          });
          alert.success(response.data.message);
          // console.log(response);
          getLeaveList();
          getLeaveByType();
        }
      );
    });
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-6">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Apply leave</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label>Leave Type*</label>
                        <select
                          className="form-control"
                          name="leave_typ"
                          required
                          value={leave_typ}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Select Leave Type</option>
                          {List.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label>From*</label>
                        <DatePicker
                          onChange={(e) => fromDateChage(e)}
                          //onChange={e => setfromDate(e)}
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          placeholderText="From Date"
                          selected={fromDate}
                          minDate={fromDate}
                          maxDate={mxDate}
                          name="from_dt"
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>To*</label>
                        <DatePicker
                          selected={toDate}
                          minDate={toDate}
                          maxDate={mxDate}
                          onChange={(e) => toDateChage(e)}
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          placeholderText="To Date"
                          name="to_dt"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label>Reason*</label>
                        <textarea
                          className="form-control"
                          name="reason"
                          value={reason}
                          onChange={(e) => onInputChange(e)}
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Leave summary</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">
                            {creadited_leaves + carryforward_leaves}
                          </span>
                          <div className="databox-text darkgray">
                            Total Leave Credited
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themesecondary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themesecondary">
                            {debited_leaves}
                          </span>
                          <div className="databox-text darkgray">
                            Total Leave Taken
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-12"
                      style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      <table
                        style={{ width: "100%", border: "1px solid #a3a3a3" }}
                      >
                        <tr
                          style={{
                            border: "1px solid #a3a3a3",
                            backgroundColor: "#51445f",
                            color: "white",
                          }}
                        >
                          <th
                            style={{
                              border: "1px solid #a3a3a3",
                              width: "10%",
                            }}
                          >
                            #
                          </th>
                          <th
                            style={{
                              border: "1px solid #a3a3a3",
                              width: "30%",
                            }}
                          >
                            Leave Type
                          </th>
                          <th
                            style={{
                              border: "1px solid #a3a3a3",
                              width: "30%",
                            }}
                          >
                            LOP
                          </th>
                          <th
                            style={{
                              border: "1px solid #a3a3a3",
                              width: "30%",
                            }}
                          >
                            Total Available Till Now
                          </th>
                        </tr>
                        {leavestypelist.map((v, k) => {
                          var total_till_now =
                            Number(v.carryforward_cnt) +
                            Number(v.credit_cnt) -
                            Number(v.debit_cnt);
                          return (
                            <tr style={{ border: "1px solid #a3a3a3" }}>
                              <th style={{ border: "1px solid #a3a3a3" }}>
                                {k + 1}
                              </th>
                              <th style={{ border: "1px solid #a3a3a3" }}>
                                {v.name}
                              </th>
                              <th
                                style={{
                                  border: "1px solid #a3a3a3",
                                  backgroundColor: "#fb6e52",
                                }}
                              >
                                {Number(v.lop_cnt)}
                              </th>
                              <th
                                style={{
                                  border: "1px solid #a3a3a3",
                                  backgroundColor: "#a0d468",
                                }}
                              >
                                {total_till_now > 0 ? total_till_now : 0}
                              </th>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themefourthcolor">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themefourthcolor">
                            {carryforward_leaves}
                          </span>
                          <div className="databox-text darkgray">
                            Carry forward from last year
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-user"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">
                            {first_name} {last_name}
                          </span>
                          <div className="databox-text darkgray">
                            Approval Authority
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">List of leaves</h4>
                  </div>
                </div>
                <div className="iq-card-body padLRTzero">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped table-bordered"
                      role="grid"
                      aria-describedby="user-list-page-info"
                    >
                      <thead>
                        <tr className="clr">
                          <th>#</th>
                          <th>Applied On</th>
                          <th>Leave Type</th>
                          <th>Reason</th>
                          <th>Days</th>
                          <th>Date</th>
                          <th>Opening</th>
                          <th>Available</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveList.map((val, key) => {
                          if (val.status == 0) {
                            var status = (
                              <div className=" badge badge-pill badge-warning">
                                Pending
                              </div>
                            );
                          }
                          if (val.status == 1) {
                            var status = (
                              <div className=" badge badge-pill badge-success">
                                Approved
                              </div>
                            );
                          }
                          if (val.status == 2) {
                            var status = (
                              <div className=" badge badge-pill badge-danger">
                                Cancelled
                              </div>
                            );
                          }

                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{val.applied_on}</td>
                              <td>{val.name}</td>
                              <td>{val.reason}</td>
                              <td>{val.leave_count}</td>
                              <td>
                                {val.leave_start_date1} to {val.leave_end_date1}
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                {status}
                                {/* <div className=" badge badge-pill badge-success">Approved</div>
                                             <div className=" badge badge-pill badge-danger">Cancelled</div>
                                             <div className=" badge badge-pill badge-warning">Pending</div> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveApply;
