import React, {useState,useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../pages/admin.css';


const Formula_list = () => {
    const alert = useAlert();
    let history = useHistory();

    useEffect(() => {getFormulaData();}, []);
  
    const [headList, setFormulaHead] = useState([]);
 
    const getFormulaData = () => {
        Apiconnect.getData(`formula/getall/${Apiconnect.get_company_id()}`).then((response) => {
         // Axios.get(`http://localhost:3050/api/tush/getAllfm`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setFormulaHead(_xtract.reverse());
       });
    }

    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {
        Apiconnect.deleteData(`formula/delete/${id}`).then((response) => {
          alert.success(response.data.message); 
          getFormulaData();     
       });
     }
     const toggle_Formula = id => {

      var class1 = document.getElementById('id_'+id).getAttribute("class");
         if(class1 === 'hide'){
            document.getElementById('id_'+id).setAttribute("class", "show");
         }else{
            document.getElementById('id_'+id).setAttribute("class", "hide");
         }
      }

return (
        <>
              <div id="content-page" class="content-page">
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div style={{float:'left'}}  class="iq-header-title">
                              <h4 className="card-title">Formula List</h4> 
                              
                           </div>
                           <Link style={{float:'right'}} className="btn btn-success"  to="/formula_add" >Add New</Link>
                        </div>
                        <div class="iq-card-body">
                        <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Title</th>
                                       <th scope="col">Description</th>
                                       <th scope="col">Head</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                       headList.map((val,key)=>{

                                          if(val.head_typ === 'earning'){
                                             var abb = val.earning_name;
                                          }else{
                                             var abb = val.deduction_name;
                                          }
                                        return(
                                        <>
                                          <tr key={key}>
                                          <td>{key+1}</td>
                                          <td>{val.title}</td>
                                          <td>{val.description}</td>
                                          <td>{abb}</td>
                                          <td style={{width:'200px'}}> 
                                             <button onClick={() =>toggle_Formula(val.id)}
                                             className="btn btn-primary mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                          {/* <button onClick={() => {ondlt(val.id);}} className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                          <button onClick={() => {ondlt(val.id);}}
                                           className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                          {/* <button onClick={() => {gethead(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button> */}
                                                <Link   to={`/formula_edit/${val.id}`} 
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                             </td>
                                       </tr>
                                       <tr className='hide' id={'id_'+val.id} >
                                       <td colSpan="5">
                                       <table className="" style={{width:'100%'}}>
                                                     <thead className="">
                                                         <tr>
                                                            <th className='bgcls'>#</th>
                                                            <th className='bgcls'>If (condition)</th>
                                                            <th className='bgcls'>Operator</th>
                                                            <th className='bgcls'>Chk Value</th>
                                                            <th className='bgcls'>(Then)Formula</th>
                                                            <th className='bgcls'>(Then)Formula</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody className="">
                                       {
                                           val.formula.map((fm,fmk)=>{
                                             return(
                                                <tr>
                                                   <td>{fmk+1}</td>
                                                   <td>{fm.if_condition}</td>
                                                   <td>{fm.f_operator}</td>
                                                   <td>{fm.check_value}</td>
                                                   <td>{fm.f_then}</td>
                                                   <td>{fm.f_else}</td>
                                                </tr>
                                             )
                                           })  
                                       }
                                       </tbody>
                                        </table>
                                       </td>
                                       </tr>
                                    
                                      </>
                                        )
                                       })
                                    }
                                    
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>  
        </>
    )
}

export default Formula_list
